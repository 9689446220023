import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut, Maximize2, Minimize2 } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function PdfViewer() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);
  const touchStartRef = useRef(null);
  const touchMoveRef = useRef(null);
  const location = useLocation();

  // New state for preloading pages
  const [pageCache, setPageCache] = useState({});

  useEffect(() => {
    const loadPdf = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setIsLoading(false);
        return;
      }

      try {
        //setPdfUrl(`http://localhost:4999/secure-pdf/${token}`);
        setPdfUrl(`https://api.economyofwisdom.com/secure-pdf/${token}`);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
      setIsLoading(false);
    };

    loadPdf();
  }, [location]);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }, []);

  const changePage = useCallback((offset) => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = Math.min(Math.max(prevPageNumber + offset, 1), numPages);
      // Preload next and previous pages
      preloadPages(newPageNumber);
      return newPageNumber;
    });
  }, [numPages]);

  // Function to preload pages
  const preloadPages = useCallback((currentPage) => {
    const pagesToPreload = [currentPage - 1, currentPage, currentPage + 1];
    pagesToPreload.forEach(pageNum => {
      if (pageNum > 0 && pageNum <= numPages && !pageCache[pageNum]) {
        setPageCache(prev => ({ ...prev, [pageNum]: true }));
      }
    });
  }, [numPages, pageCache]);

  const zoomIn = useCallback(() => setScale((s) => Math.min(s + 0.1, 3)), []);
  const zoomOut = useCallback(() => setScale((s) => Math.max(s - 0.1, 0.5)), []);
  
  const toggleFullWidth = useCallback(() => {
    setIsFullWidth((prev) => !prev);
    setScale(1);
  }, []);

  const handleTouchStart = useCallback((e) => {
    if (e.touches.length === 2) {
      touchStartRef.current = e.touches;
    }
  }, []);

  const handleTouchMove = useCallback((e) => {
    if (touchStartRef.current && e.touches.length === 2) {
      touchMoveRef.current = e.touches;
      
      const startDistance = Math.hypot(
        touchStartRef.current[0].clientX - touchStartRef.current[1].clientX,
        touchStartRef.current[0].clientY - touchStartRef.current[1].clientY
      );
      
      const moveDistance = Math.hypot(
        touchMoveRef.current[0].clientX - touchMoveRef.current[1].clientX,
        touchMoveRef.current[0].clientY - touchMoveRef.current[1].clientY
      );
      
      const scaleDiff = moveDistance / startDistance;
      setScale((s) => Math.min(Math.max(s * scaleDiff, 0.5), 3));
      
      touchStartRef.current = touchMoveRef.current;
    }
  }, []);

  const handleTouchEnd = useCallback(() => {
    touchStartRef.current = null;
    touchMoveRef.current = null;
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!pdfUrl) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 text-gray-800">
      <Header />
      
      <main className="container mx-auto px-4 sm:px-6 pt-2 sm:pt-4 pb-8 sm:pb-12">
        <section className="mb-8 sm:mb-12 max-w-6xl mx-auto">
          <h1 className="text-2xl sm:text-3xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-gray-600">
            Economy of Wisdom - Preview
          </h1>
        </section>
        <section className="mb-16">
          <div className="relative z-10 bg-white p-4 rounded-lg shadow-lg"
               onTouchStart={handleTouchStart}
               onTouchMove={handleTouchMove}
               onTouchEnd={handleTouchEnd}>
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              className="flex justify-center"
              onLoadError={(error) => {
                console.error('Error loading PDF:', error);
              }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  scale={isFullWidth ? 'full-width' : scale}
                  width={isFullWidth ? window.innerWidth - 32 : undefined}
                  className={pageNumber === index + 1 ? '' : 'hidden'}
                  loading={<div className="text-center py-4">Loading page...</div>}
                />
              ))}
            </Document>
            <div className="mt-4 flex flex-wrap justify-center items-center gap-2">
              <button
                onClick={() => changePage(-1)}
                disabled={pageNumber <= 1}
                className="flex items-center px-3 py-1 bg-blue-500 text-white rounded-lg disabled:bg-gray-300 text-sm"
              >
                <ChevronLeft size={16} />
                Prev
              </button>
              <span className="text-sm">
                Page {pageNumber} of {numPages}
              </span>
              <button
                onClick={() => changePage(1)}
                disabled={pageNumber >= numPages}
                className="flex items-center px-3 py-1 bg-blue-500 text-white rounded-lg disabled:bg-gray-300 text-sm"
              >
                Next
                <ChevronRight size={16} />
              </button>
              <button onClick={zoomOut} className="p-2 bg-gray-200 rounded-full">
                <ZoomOut size={16} />
              </button>
              <button onClick={zoomIn} className="p-2 bg-gray-200 rounded-full">
                <ZoomIn size={16} />
              </button>
              <button onClick={toggleFullWidth} className="p-2 bg-gray-200 rounded-full">
                {isFullWidth ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
              </button>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default PdfViewer;

import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

function CheckoutSuccess() {
  const [secureLink, setSecureLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [copyStatus, setCopyStatus] = useState('Copy Link');
  const location = useLocation();

  useEffect(() => {
    const generateSecureLink = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get('session_id');

      if (!sessionId) {
        setError('Invalid session ID');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch('https://api.economyofwisdom.com/generate-secure-link', {
        //const response = await fetch('http://localhost:4999/generate-secure-link', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId }),
        });

        if (!response.ok) {
          throw new Error('Failed to generate secure link');
        }

        const data = await response.json();
        setSecureLink(`${window.location.origin}/pdf-viewer?token=${data.secureLink}`);
      } catch (error) {
        setError('Failed to generate secure link');
      }

      setIsLoading(false);
    };

    generateSecureLink();
  }, [location]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(secureLink).then(() => {
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus('Copy Link'), 2000);
    }, (err) => {
      console.error('Failed to copy: ', err);
      setCopyStatus('Failed to copy');
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 text-gray-800 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-2xl font-bold mb-4 text-center">Thank You for Your Purchase!</h1>
        <p className="mb-4">Access the pre-published version of the <b>Economy of Wisdom</b> using this secure link. Please save it now, as this is the only time it will be displayed.</p>
        <div className="bg-gray-100 p-4 rounded mb-4 break-all">
          <a href={secureLink} className="text-blue-600 hover:underline">{secureLink}</a>
        </div>
        <button
          onClick={handleCopyLink}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200 mb-4"
        >
          {copyStatus}
        </button>
        <Link 
          to={secureLink}
          className="block w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200 text-center"
        >
          Go to Economy of Wisdom
        </Link>
      </div>
    </div>
  );
}

export default CheckoutSuccess;

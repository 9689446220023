import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isPdfViewerPath = location.pathname.startsWith('/pdf-viewer');

  if (isPdfViewerPath) {
    return (
      <header className="bg-gradient-to-r from-blue-50 to-gray-100 shadow-md">
        <div className="container mx-auto px-2 py-0.5">
          <div className="flex items-center justify-between">
            <Link to="/" className="flex items-center">
              <img 
                src="/economyofwisdom_nobackground.jpg" 
                alt="Economy of Wisdom Logo" 
                className="h-12 sm:h-14 md:h-14 lg:h-14 mr-1"
              />
              <h1 className="text-lg sm:text-xl md:text-xl lg:text-2xl font-bold text-gray-800 whitespace-nowrap">
                Economy of Wisdom
              </h1>
            </Link>
            <Link to="/" className="text-blue-600 hover:text-blue-800 transition-colors">
              Back to Home
            </Link>
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className="bg-gradient-to-r from-blue-50 to-gray-100 shadow-md">
      <div className="container mx-auto px-2 py-0.5">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="flex items-center w-full sm:w-auto justify-between sm:justify-start">
            <img 
              src="/economyofwisdom_nobackground.jpg" 
              alt="Economy of Wisdom Logo" 
              className="h-12 sm:h-14 md:h-14 lg:h-14 mr-1"
            />
            <h1 className="text-lg sm:text-xl md:text-xl lg:text-2xl font-bold text-gray-800 whitespace-nowrap">
              Economy of Wisdom
            </h1>
            <button 
              className="sm:hidden text-gray-600 hover:text-gray-900 focus:outline-none"
              onClick={toggleMenu}
              aria-label="Toggle menu"
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
          </div>
          <nav className={`${isMenuOpen ? 'flex' : 'hidden'} sm:flex flex-col sm:flex-row items-center w-full sm:w-auto mt-1 sm:mt-0`}>
            <a href="#about" className="w-full sm:w-auto text-center py-0.5 sm:py-0 sm:ml-2 text-gray-700 hover:text-blue-600 transition-colors">About</a>
            <a href="#themes" className="w-full sm:w-auto text-center py-0.5 sm:py-0 sm:ml-2 text-gray-700 hover:text-blue-600 transition-colors">Themes</a>
            <a href="#faq" className="w-full sm:w-auto text-center py-0.5 sm:py-0 sm:ml-2 text-gray-700 hover:text-blue-600 transition-colors">FAQ</a>
            <a href="#author" className="w-full sm:w-auto text-center py-0.5 sm:py-0 sm:ml-2 text-gray-700 hover:text-blue-600 transition-colors">Author</a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

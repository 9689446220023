import React, { useState, useRef } from 'react';
import { Play, Pause, SkipBack, SkipForward, Volume2, VolumeX } from 'lucide-react';

const MediaPlayer = ({ src, title }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSkip = (seconds) => {
    videoRef.current.currentTime += seconds;
  };

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(!isMuted);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md max-w-xl mx-auto">
      <h3 className="text-lg font-semibold mb-4 text-center">{title}</h3>
      <div className="aspect-w-16 aspect-h-9 mb-4">
        <video 
          ref={videoRef} 
          src={src} 
          className="w-full h-full object-contain"
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        >
          Your browser does not support the video element.
        </video>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <button onClick={() => handleSkip(-10)} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
          <SkipBack size={20} />
        </button>
        <button onClick={togglePlayPause} className="p-3 rounded-full bg-blue-500 hover:bg-blue-600 text-white">
          {isPlaying ? <Pause size={24} /> : <Play size={24} />}
        </button>
        <button onClick={() => handleSkip(10)} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
          <SkipForward size={20} />
        </button>
        <button onClick={toggleMute} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
          {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
        </button>
      </div>
    </div>
  );
};

export default MediaPlayer;

import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import stripePromise from './stripe-config';
import LandingPage from './pages/LandingPage';
import PdfViewer from './pages/PdfViewer';
import CheckoutSuccess from './components/CheckoutSuccess';

function App() {
  return (
    <Router>
      <Elements stripe={stripePromise}>
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/pdf-viewer" element={<PdfViewer />} />
            <Route path="/success" element={<CheckoutSuccess />} />
          </Routes>
        </div>
      </Elements>
    </Router>
  );
}

export default App;

import React from 'react';
import { Typography, Box } from '@mui/material';
import { LocalOffer } from '@mui/icons-material';

const PreReleaseAlert = () => {
  return (
    <Box className="bg-gradient-to-r from-blue-100 to-gray-100 text-blue-800 p-4 rounded-lg shadow-md mb-4 flex flex-col items-center">
      <div className="flex items-center mb-2 justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
        </svg>
        <Typography variant="subtitle1" className="font-bold text-blue-800">
          Chapter 1 Available Now!
        </Typography>
      </div>
      <Box className="text-center">
        <Typography variant="body2" className="text-blue-700 flex items-center justify-center">
          <LocalOffer className="mr-1 text-blue-600" fontSize="small" />
          Pay What You Want - Set Your Price for the Entire Book for As Low As $1 USD
        </Typography>
      </Box>
    </Box>
  );
};

export default PreReleaseAlert;

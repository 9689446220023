import React, { useState, useEffect } from 'react';
import { Slider, Button, Typography, Box, Paper, } from '@mui/material';
import PreReleaseAlert from '../components/PreReleaseAlert';
import { useStripe } from '@stripe/react-stripe-js';

function PreorderSlider() {
  const [value, setValue] = useState(29);
  const minValue = 1;
  const maxValue = 59;
  const stripe = useStripe();
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
      const diff = endOfDay - now;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      setTimeLeft(`${hours}h ${minutes}m`);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePreorder = async () => {
    if (!stripe) {
      return;
    }

    const response = await fetch('https://api.economyofwisdom.com/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: value * 100,
      }),
    });

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error);
    }
  };

  return (
    <Paper elevation={3} className="bg-gradient-to-br from-blue-50 to-gray-100 w-full max-w-[370px] mx-auto text-center p-4 sm:p-6 rounded-lg">
      <PreReleaseAlert />
      <Typography variant="h6" className="font-bold text-black mb-4">
        Preorder Amount: ${value} USD
      </Typography>
      <Box className="px-4">
        <Slider
          value={value}
          onChange={handleChange}
          aria-labelledby="preorder-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={minValue}
          max={maxValue}
          className="text-blue-500"
        />
      </Box>
      <Button 
        variant="contained" 
        onClick={handlePreorder}
        className="w-full mt-4 font-bold py-3 px-6 bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-700 hover:to-blue-900 text-white text-lg rounded-full shadow-lg transform transition duration-200 hover:scale-105"
      >
        PREORDER NOW
      </Button>
      <Box className="mt-6 flex justify-center">
        <img src="/stripebadge.png" alt="Stripe secure payment badge" className="w-full max-w-[250px] h-auto object-contain" />
      </Box>
      <Box className="mt-6 space-y-2 text-gray-700">
        <Typography variant="caption">
          • You'll be redirected to a secure Stripe payment page<br />
        </Typography>
        <Typography variant="caption">
          • Access Chapter 1 immediately after payment via a private link<br />
        </Typography>
        <Typography variant="caption">
          • Get consequent chapters as they are released and the full book upon publication
        </Typography>
      </Box>
  
    </Paper>
  );
}

export default PreorderSlider;

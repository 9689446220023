import React from 'react';

function ThemeCard({ icon: Icon, title, description }) {
  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-sm transition duration-300 hover:shadow-md relative overflow-hidden">
      <div className="absolute top-0 right-0 w-8 h-8 bg-gray-200 transform rotate-45 translate-x-4 -translate-y-4"></div>
      <div className="relative z-10">
        <Icon className="w-12 h-12 text-blue-600 mb-4" />
        <h4 className="text-lg font-semibold mb-2">{title}</h4>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
    </div>
  );
}

export default ThemeCard;

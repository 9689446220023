// src/components/ExcerptSection.js
import React from 'react';

function ExcerptSection() {
  return (
    <section className="py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-8 text-center relative">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-gray-600">
              Why This Book
            </span>
          </h2>
          <div className="bg-white p-8 sm:p-12 rounded-lg shadow-lg relative overflow-hidden">
            {/* Folded corner effect */}
            <div className="absolute top-0 right-0 w-16 h-16 bg-gray-200 transform rotate-45 translate-x-8 -translate-y-8"></div>
            
            <blockquote className="text-lg text-gray-700 relative z-10">
              <p className="mb-4 first-letter:text-4xl first-letter:font-bold first-letter:mr-1 first-letter:float-left">
                "...The choice to write this book comes from my understanding of humanity's dual nature—our unconstrained ability to empower and dominate. In recent years, our brilliance has led us to proliferate the ultimate technology of power: artificial intelligence. This technology reflects our collective intelligence, and its growing capability reflects what we put in.
              </p>
              <p className="mb-4">
                Artificial intelligence is power, as is all technology, and the power can be channeled as empowerment or dominance of others. With AI capable of exceeding human performance at various tasks becoming a reality this decade, we don't have much time left. If we project empowerment into AI, it will empower us far beyond our current capabilities. If we project dominance, it will dominate us beyond our hope of escaping.
              </p>
              <p>
                AI misalignment risks a superintelligent system that is not empathetic to human values and disempowers most of us. That is why I am writing this book now, hoping that some people will read it, decide to become self-fulfilled conscious agents, and make the right choices to ensure that AI empowers us all. This collective choice of empowerment or disempowerment is ours to make..."
              </p>
            </blockquote>
            
            <div className="mt-8 text-right">
              <span className="font-semibold text-gray-900">— Economy of Wisdom</span>
            </div>
            
            {/* Page number */}
            <div className="absolute bottom-4 right-4 text-gray-400">1</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExcerptSection;
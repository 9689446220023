import React from 'react';
import { Linkedin, Twitter, Youtube } from 'lucide-react';

const SocialLinks = () => {
  return (
    <div className="flex justify-center space-x-4">
      <a href="https://www.linkedin.com/company/economyofwisdom" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600">
        <Linkedin size={24} />
      </a>
      <a href="https://twitter.com/EconomyOfWisdom" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-400">
        <Twitter size={24} />
      </a>
      <a href="https://economyofwisdom.substack.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-orange-500">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M22 20h-6v-8h6v8z" />
          <path d="M16 4h6v8h-6V4z" />
          <rect x="2" y="4" width="12" height="16" />
        </svg>
      </a>
      <a href="https://www.youtube.com/@EconomyofWisdom" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-red-600">
        <Youtube size={24} />
      </a>
    </div>
  );
};

export default SocialLinks;

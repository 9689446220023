import React from 'react';
import { Mail, Linkedin } from 'lucide-react';

function AuthorSection() {
  return (
    <section id="author" className="mb-16">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-6 text-center relative">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-gray-600">
          About the Author
        </span>
      </h2>
      <div className="bg-white p-8 rounded-lg shadow-lg relative overflow-hidden">
        <div className="absolute top-0 right-0 w-16 h-16 bg-gray-200 transform rotate-45 translate-x-8 -translate-y-8"></div>
        <div className="relative z-10">
          <div className="flex flex-col md:flex-row items-center">
            <div className="w-32 h-32 bg-gray-300 rounded-full mb-4 md:mb-0 md:mr-6 flex-shrink-0">
              <img
                src="/sev-new.jpeg"
                alt="Sev Geraskin"
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <div>
              <h4 className="text-xl font-semibold mb-2"><span className="mr-2">♾️</span>sev geraskin</h4>
              <p className="text-gray-600 mb-4">
                seasoned entrepreneur, author, and technologist with over 20 years of experience in the tech industry
              </p>
              <div className="flex space-x-4">
                <a
                  href="mailto:economyofwisdom@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
                  aria-label="Email Sev Geraskin"
                >
                  <Mail size={24} />
                </a>
                <a
                  href="https://www.linkedin.com/in/past5/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
                  aria-label="Connect with Sev Geraskin on LinkedIn"
                >
                  <Linkedin size={24} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-4 right-4 text-gray-400">3</div>
      </div>
    </section>
  );
}

export default AuthorSection;
